import { gql } from '@apollo/client';

const getAllPosts = (endCursor = '') => ({
  query: gql`
    {
      posts(first: 100, after: "${endCursor}", where : { status: PUBLISH }) {
        nodes {
          date
          title
          uri
          status
          slug
          postId
          excerpt
          featuredImage {
            node {
              uri
              title
              sourceUrl
            }
          }
          categories {
            nodes {
              id
              name
              slug
              termTaxonomyId
            }
          }
          author {
            node {
              name
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  `,
});

export default getAllPosts;
