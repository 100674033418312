const feesNotes = [
  {
    image: '/images/coin.png',
    mobileImage: '/images/coin-stack-white-background.png',
    heading: 'High value settlements',
    paragraph: 'Cases concluded quickly & at the highest value',
  },
  {
    image: '/images/ladyGlasses.png',
    mobileImage: '/images/lady-glasses-white-background.png',
    heading: 'Transparent fees',
    paragraph: 'Full up-front info on fees, with no unexpected bills',
  },
  {
    image: '/images/blueManTie.png',
    mobileImage: '/images/blue-tie-white-background.png',
    heading: 'Outstanding representation',
    paragraph: 'Largest & most experienced team of employee experts in the UK',
  },
];

export default feesNotes;
