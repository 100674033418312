import { useState, useEffect } from 'react';
import createApolloClient from '../../api';
import getAllReviews from '../../api/queries/reviews/getAllReviews';
import ReviewsIo from '../ReviewsIo';
import CustomReviews from '../CustomReviews';

const client = createApolloClient();

const Reviews = ({ classNames = '', children, isFromPage = false, data }) => {
  const [reviewData, setReviewData] = useState([]);

  useEffect(() => {
    (async () => {
      const reviewsContent  = await client.query(getAllReviews());
      setReviewData(reviewsContent);
    })();
  }, []);

  return (
    <div className={`reviews__container ${classNames}`}>
      <div className="reviews__stars" data-bg="/images/stars.svg" />
      {children}
      <ReviewsIo />
      <CustomReviews customReviewsData={reviewData} />
      {!isFromPage && (
        <p className="reviews__container__footer-text subtitle_2">
          {data ? data : <p>Our many <a href="/testimonials">5 star reviews</a> are testament to our proven track record in negotiating high value settlements</p>}
        </p>
      )}
    </div>
  );
};

export default Reviews;
