import sanitizeHTML from '../../utils/sanitiseHTML';

const CustomReviews = (customReviewsData) => {
  const reviewContent = customReviewsData?.customReviewsData?.data?.reviews?.nodes;
  return (
    <div className="custom__reviews__section">
      <div className="custom__reviews__section__inner-wrapper-slider">
          {reviewContent?.map((review) => (
            <section className="custom__reviews__section__card_wrapper">
              <div className="subtitle_1" dangerouslySetInnerHTML={{ __html: sanitizeHTML(review?.title) }} />
              <div className='custom__reviews__section__verified__image__wrapper'>
                <img className="custom__reviews__section__verified__image" src="/images/verified-star.svg" width="20" height="20"/>
                <p className="custom__reviews__section__verified_text">Verified Customer</p>
              </div>
              <div className="subtitle_1" dangerouslySetInnerHTML={{ __html: sanitizeHTML(review?.excerpt) }} />
            </section>
          ))}
      </div>
    </div>
  );
};

export default CustomReviews;
