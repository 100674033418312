import { gql } from '@apollo/client';

const getAllCategories = () => ({
  query: gql`
    query allCategories {
      categories {
        nodes {
          name
          link
          parentId
          uri
          id
          count
          slug
          termTaxonomyId
        }
      }
    }
  `,
});

export default getAllCategories;
