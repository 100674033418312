import { ReactComponent as FullBlueTick } from '../../public/images/infograph-stage-4.svg';
import { ReactComponent as WhiteTick } from '../../public/images/infograph-stage-5.svg';

const Representation = () => {
  return (
    <div className="representation-process__container">
      <h2>1 hour response time</h2>
      <p className="subtitle_1">Contact us and we will reply within 1 hour letting you know your options</p>
      <div className="representation-process__flow">
        <div className="representation-process__card">
          <a href="/contact-us" className="representation-process__card__image representation-process__card__image--contact" data-bg="/images/contact-us.png"/>
          <p>Tell us about your case</p>
        </div>
        <div className="representation-process__single-arrow" data-bg="/images/single-arrow.svg"/>
        <div className="representation-process__card">
          <div className="representation-process__card__image representation-process__card__image--one-hour" data-bg="/images/within-hour.png"/>

          <p>We’ll be in touch within 1 working hour</p>
        </div>
        <div className="representation-process__single-arrow" data-bg="/images/single-arrow.svg"/>
        <div className="representation-process__card">
          <div className="representation-process__card__image representation-process__card__image--blue-tick" data-bg="/images/big-blue-tick.png"/>
          <p>We aim to offer No Win No Fee if possible</p>
        </div>
        <div className="representation-process__double-arrow" data-bg="/images/double-arrow.svg"/>
        <div className="representation-fee">
          <div className="representation-fee__card">
            <FullBlueTick />
            <p>
              If No Win No Fee is possible, your lawyer will explain your journey
            </p>
          </div>
          <div className="representation-fee__card">
            <WhiteTick />
            <p>Otherwise, we will provide you with a competitive quote</p>
          </div>
        </div>
      </div>
      <p className="representation-process__footer-text subtitle_2">
        Tell us about your case and receive a clear and concise fee plan to fit your circumstances
      </p>
    </div>
  );
};

export default Representation;
