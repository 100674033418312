import { gql } from '@apollo/client';

const getAllReviews = () => ({
  query: gql`
    {
      reviews(first: 100, where: { status: PUBLISH }) {
        nodes {
          title
          excerpt
        }
      }
    }
  `,
});

export default getAllReviews;
