export const convertDateToHuman = (originalDate) => {
  const parsedDate = new Date(originalDate);
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const year = parsedDate.getFullYear();
  const day = parsedDate.getDate();
  const month = parsedDate.getMonth();
  const formattedMonth = monthNames[month];
  const formattedDate = formattedMonth + ' ' + (day < 10 ? '0' : '') + day + ', ' + year;
  return formattedDate;
};
